<template>
  <div>
   <div class="item" v-if="host!='allenandunwin'">
      <div class="image_thumb">
         <router-link
            :to="card_data.url"
            >
            <clazy-load :src="card_data.hero_image">
               <img
                  :src="card_data.hero_image"
                  class="image_fulltext"
                  :alt='card_data.title'
                  />
            </clazy-load>
         </router-link>
      </div>
      <div class="text">
         <h4 class="newsflash-title news">
            <router-link
               :to="card_data.url"
               >{{card_data.title}}</router-link>
         </h4>
         <p>{{card_data.summary}}</p>
         <div class="date">{{ card_data.from_date }}</div>
      </div>
      <router-link
         class="readmore"
         :to="card_data.url"
         >
         <span>Read more &gt;</span>
      </router-link>
   </div>
   <div class="item" v-else>
      <div class="card">
         <router-link
            :to="card_data.url">
            <clazy-load :src="card_data.hero_image">
               <img
                  :src="card_data.hero_image"
                  height="200"
                  class="img-border"
                  :alt='card_data.title'
                  />
            </clazy-load>
         </router-link>
         <div class="container">
            <h4>
               <b>
                  <router-link
                     :to="card_data.url">
                     {{card_data.title}}
                  </router-link>
               </b>
            </h4>
            <p class="gry">Read the exclusive story behind it.</p>
            <router-link
               class="link"
               :to="card_data.url"
               >
               <span>SHOW MORE</span>
            </router-link>
         </div>
      </div>
   </div>
</div>
</template>

<script>
export default {
  name: "blog-card",
  props: ['card_data'],
  data() {
    return {
      host : this.$store.state.site_data.host
    };
  },
  mounted() {}
};
</script>

<style>
.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  margin-bottom: 30px;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 14px 13px 20px 27px;
}
.img-border{
  border: 7px solid  #6e6c6c;
  width: 95%;
  padding: 3px;
  background-color: #6e6c6c;
}
.gry{
  color: #a19b9b;
  font-size: 12px;
}
.link{
  color: #41525c;
  text-decoration: revert;
}
</style>